<template>
  <div class="container">
    <div class="top">
      <div class="top-select">
        <Select size="default" v-model="selectedUserList" multiple placeholder="target members">
          <Option v-for="item in userList" :value="item.userId" :key="item.userId">{{ item.username }}
          </Option>
        </Select>
      </div>
      <div class="top-button">
        <Button @click="confirm" type="primary">Confirm</Button>
      </div>
    </div>
    <div class="left">
      <Tree
        :data="treeData"
        ref="tree"
        show-checkbox
        show-expand
        multiple
        @on-check-change="checkChange"
        @on-select-change="selectChange"
      ></Tree>
    </div>
    <div class="right">
      <div class="content" v-if="currentFile.selected">
        <div v-if="!!currentFile.children">{{defaultContent}}</div>
        <div v-else-if="currentFile.fileContent.length">
          <div class="content-line" 
            v-for="(line, index) in currentFile.fileContent" 
            :key="index" @click="onLineSelected(index)"
          >
            <span :class="{'content-line-icon':true, 'content-line-icon-selected':currentFile.selectedLine.indexOf(index)!==-1 || index===lastSelectedLine}">
              <Icon type="md-checkmark" v-if="index !== lastSelectedLine"/>
              <Icon type="md-flag" v-else/>
            </span>
            <span class="content-line-num">{{index + 1}}</span>{{line}}
          </div>
        </div>
        <div v-else>This file is empty.</div>
      </div>
      <div v-else>{{defaultContent}}</div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: ".",
      branch: "master",
      treeData: [],
      /**
       * 正在调用getFileContent
       */
      gettingFileContent: false,
      currentFile: {},
      defaultContent: 'Select a file to see it\'s content!',
      lastSelectedLine: -1,
      projectId: '',
      userList: [],
      selectedUserList: []
    }
  },
  mounted() {
    this.projectId = this.$route.query.id
    this.getAllFiles()
    this.getMembers()
  },
  methods: {
    confirm () {
      let Tself = this
      this.$Modal.confirm({
        content: `<p>Are you sure to open these files to selected users?</p>`,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: () => {
          if (!Tself.selectedUserList.length)  Tself.$Message.error('You haven\'t selected any user!')
          else if (!Tself.$refs.tree.getCheckedAndIndeterminateNodes().length) Tself.$Message.error('You haven\'t selected any file!')
          else {
            console.log(this.projectId)
            console.log(this.$cookies.get('user_id'))
            console.log(this.selectedUserList)
            this.$store.dispatch('grantSegment', {
              projectId: this.projectId,
              sourceUserId: this.$cookies.get('user_id'),
              targetUserIds: this.selectedUserList,
              fileNameAndMarks: this.$refs.tree.getCheckedAndIndeterminateNodes()
            }).then(res => {
              console.log(res)
              this.$Message.success('Successfully shared files to selected users!')
            }).catch(err => {
              console.log(err)
              this.$Message.error(err.message || 'Unknown error!')
            })
          }
        }
      })
    },
    getMembers() {
      let Tself = this
      this.$store.dispatch('getProjectContribution', this.projectId).then(res => {
        let userList = res.data.data.items.content.filter(user => user.userId !== Tself.$cookies.get('user_id')).map(user => {
          return {
            userId: user.userId
          }
        })
        let count = 0
        userList.forEach(user => {
          Tself.$store.dispatch('findUserById', user.userId).then(res => {
            user.username = res.data.data.items.username
            count++
            if(count === userList.length) this.userList = userList
          })
        })
      })
    },
    checkChange(allNode, currentNode) {
      console.log(this.$refs.tree.getCheckedAndIndeterminateNodes())
      if (!currentNode.isDir) {
        currentNode.selectedLine = []
        if (currentNode.checked) {
          if (currentNode.fileContent.length) {
            for (let i = 0 ; i < currentNode.fileContent.length ; i++) {
              currentNode.selectedLine.push(i)
            }
          }
        }
      }
    },
    selectChange(allNode, currentNode) {
      if (this.gettingFileContent) {
        currentNode.selected = !currentNode.selected
        return
      }
      let Tself = this
      this.lastSelectedLine = -1
      this.currentFile.selected = false
      if (this.currentFile === currentNode) return
      if (currentNode.isDir) {
        this.currentFile = currentNode
        return
      }
      // 已经获取过content
      if (currentNode.fileContent.length) {
        this.currentFile = currentNode
        return
      }
      this.gettingFileContent = true
      this.defaultContent = 'Getting the content of this file..'
      this.$store.dispatch('getFileContent', {
        path: currentNode.absolutePath,
        selectedLine: []
      }).then(res => {
        currentNode.fileContent = res.data.data.items
        if (currentNode.checked) {
          if (currentNode.fileContent.length) {
            for (let i = 0 ; i < currentNode.fileContent.length ; i++) {
              currentNode.selectedLine.push(i)
            }
          }
        }
        Tself.currentFile = currentNode
        Tself.defaultContent = 'Select a file to see it\'s content!'
        Tself.gettingFileContent = false
      }).catch(err => {
        Tself.currentFile = currentNode
        Tself.defaultContent = 'Select a file to see it\'s content!'
        Tself.gettingFileContent = false
      })
    },
    onLineSelected(index) {
      // 从开始的Line 到 结束的Line 转换选中状态
      if (this.lastSelectedLine === -1) {
        this.lastSelectedLine = index
        return
      }
      if (this.lastSelectedLine < index) {
        this.lastSelectedLine = this.lastSelectedLine + index
        index = this.lastSelectedLine - index
        this.lastSelectedLine = this.lastSelectedLine - index
      }
      for (let i = index; i <= this.lastSelectedLine; i++) {
        if (this.currentFile.selectedLine.indexOf(i) === -1) this.currentFile.selectedLine.push(i)
        else this.currentFile.selectedLine.splice(this.currentFile.selectedLine.indexOf(i),1)
      }
      this.lastSelectedLine = -1
      // 检查状态改变后 文件的选中状态
      console.log(this.currentFile.selectedLine)
      if (this.currentFile.selectedLine.length === this.currentFile.fileContent.length) {
        this.currentFile.checked = true
        this.currentFile.indeterminate = false
      } else if (this.currentFile.selectedLine.length > 0) {
        this.currentFile.checked = false
        this.currentFile.indeterminate = true
      } else {
        this.currentFile.indeterminate = false
        this.currentFile.checked = false
      }
    },
    getAllFiles() {
      let Tself = this
      let projectId = Tself.$cookies.get('contributionId')
      let relPath = this.path
      let branch = this.branch
      this.path = "";
      this.$store.dispatch('getAllFiles', {
        projectId,
        relPath,
        branch
      }).then(res => {
        Tself.treeData = res.data.map(item => {
          if (item.dir) {
            return {
              title: item.file,
              absolutePath: item.absolutePath,
              isDir: item.isDir,
              tag: item.tag,
              expand: false,
              commitDate: item.commitDate,
              message: item.message,
              dir: item.dir,
              line: item.line,
              loading: false,
              check: false,
              checkAll: false,
              reqPath: item.file, //用于请求的相对地址
              children: [], //用来添加文件夹下内容
            }
          }
          return {
            expand: false,
            title: item.file,
            absolutePath: item.absolutePath,
            isDir: item.isDir,
            tag: item.tag,
            commitDate: item.commitDate,
            message: item.message,
            dir: item.dir,
            line: item.line,
            check: false,
            checkAll: false,
            reqPath: relPath + item.file, //用于请求的相对地址
            fileContent: [], //文件的内容
            selectedLine: [], //选中的行
            indeterminate: false,
            checked: false
          }
        })
        Tself.treeData.forEach(item => {
          if (item.dir) {
            Tself.getChildNode(item);
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    getChildNode(nowNode) {
      let Tself = this
      let projectId = Tself.$cookies.get('contributionId')
      let relPath = nowNode.reqPath
      let branch = this.branch
      // nowNode.expand = true
      this.$store.dispatch('getAllFiles', {
        projectId,
        relPath,
        branch
      }).then(res => {
        let dto = res.data.map(item => {
          if (item.dir) {
            return {
              title: item.file,
              absolutePath: item.absolutePath,
              isDir: item.isDir,
              tag: item.tag,
              expand: false,
              commitDate: item.commitDate,
              message: item.message,
              dir: item.dir,
              line: item.line,
              loading: false,
              check: false,
              checkAll: false,
              reqPath: relPath + "/" + item.file, //用于请求的相对地址
              children: [], //用来添加文件夹下内容
            }
          }
          return {
            title: item.file,
            absolutePath: item.absolutePath,
            isDir: item.isDir,
            tag: item.tag,
            check: false,
            checkAll: false,
            commitDate: item.commitDate,
            message: item.message,
            dir: item.dir,
            line: item.line,
            reqPath: relPath + "/" + item.file, //用于请求的相对地址
            fileContent: [], //文件的内容
            selectedLine: [], //选中的行
            indeterminate: false,
            checked: false
          }
        })
        let nextNode = dto;
        if (nextNode !== null && nextNode !== undefined && nextNode !== "") {
          nowNode.children = nextNode;
          for (let i = 0; i < nextNode.length; i++) {
            if (nextNode[i].dir) {
              Tself.getChildNode(nextNode[i]);
            }
          }
        }
      }).catch(err => {
        console.log(err);
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 90%;
  height: 500px;
  text-align: left;
  overflow: auto;
  padding: 0px 20px 20px 0;
  .top {
    padding: 10px;
    float: left;
    width: calc(~"95% + 30px");
    height: 52px;
    display: flex;
    justify-content: space-between;
    &-select {
      flex-grow: 1;
      margin-right: 20px;
    }
  }
  .left {
    width: 30%;
    height: calc(~"100% - 52px");
    float: left;
    overflow: auto;
  }
  .right {
    width: 65%;
    float: left;
    margin-left: 20px;
    padding: 10px;
    height: calc(~"100% - 52px");
    border: 1px solid #aaa;
    overflow: auto;
    .content {
      &-line-icon {
        display: inline-block;
        width: 15px;
        opacity: 0;
        &-selected {
          opacity: 1;
        }
      }
      &-line-num {
        display: inline-block;
        width: 30px;
        text-align: right;
        margin-right: 10px;
      }
      &-line {
        cursor: pointer;
        white-space: pre;
      }
    }
  }
}
</style>